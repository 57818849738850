* {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    scroll-behavior: smooth;
    box-sizing: border-box;
}

:root {
    --text-light: rgba(20, 19, 19, 0.8);
    --dark: #0f141e;
    --bg-dark-transparent: rgba(20, 19, 19, 0.712);
    --text-white: rgba(228, 225, 225, 0.685);
    --white: #fff;
}

body {
    max-width: 100vw;
    overflow-x: hidden;
}

.app {
    max-width: 90vw;
    margin: 0 auto;
}

/* styling scrollbar */
::-webkit-scrollbar-track {
    border: 4px solid var(--white);
    background-color: var(--text-light);
}

::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: var(--text-light);
    border-radius: 0px;
}

@media (max-width: 700px) {
    .app {
        max-width: 100vw;
    }

    .responsive__hide {
        display: none;
    }
}
