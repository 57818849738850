.menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 0;
    background-color: var(--bg-dark-transparent);
    z-index: 10;
    display: grid;
    place-items: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
}

.menu__open {
    height: 100vh;
    visibility: visible;
    opacity: 1;
}

.menuWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menuWrapper h1 {
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 15px;
    color: var(--text-white);
}

.menuWrapper a {
    font-size: 3rem;
    margin-bottom: 10px;
    color: var(--text-white);
    font-weight: 700;
    transition: all 0.3s ease;
    cursor: pointer;
    text-decoration: none;
}

.menuWrapper a:hover {
    color: #fff;
    border-left: 8px solid #fff;
    padding-left: 5px;
}

@media (max-width: 700px) {
    .menuWrapper a {
        font-size: 2rem;
    }
}
